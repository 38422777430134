// Progress bg colours
$pg-default-bg: #eee;
// Progress bar bg colours
$bar-default-bg: #FFD232;
$bar-info-bg: #FFD232;
$bar-danger-bg: #FFD232;
$bar-success-bg: #FFD232;
$bar-warning-bg: #FFD232;


.influencer-profile {
  display: flex;
  // flex-wrap: wrap;
  gap: 1rem;
  // user-select: none;

  &__left {
    flex: 0 0 286px;

    &__inner {
      position: sticky;
      top: 150px;
    }
  }

  &__right {
    flex: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (width > 991px) {
      width: calc(100% - 302px)
    }
  }
}


.influencer-profile__left {

  &-inner {
    background-color: $color-f2f2f2;
    padding: 1.25rem;
    padding-top: 3.75rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    position: sticky;
    top: 100px;
    height: calc(100vh - 110px);
    overflow-y: auto;
    border-radius: $border-radius__10;

  }
}

.influencer-profile__image {
  width: 9.375rem;
  height: 9.375rem;
  border-radius: 50%;
  border: 9px solid $color-white;
  margin: 0 auto;
  margin-bottom: -16px;
  // position: relative;

  img {
    max-width: 100%;
    border-radius: 50%;
  }
//   .discover_subscription_dot {
//     width: 12px;
//     height: 12px;
//     right: 24px;
//     bottom: 0px;
//     top: auto;
//     border: 1px solid #c4c4c4;
//   }

//   .discover_subscription_dot_non_subscribed{
//     background-color: #FF7245;
//     }
// .discover_subscription_dot_active{
//     background-color: #5FC996;
//     }
// .discover_subscription_dot_non_active{
//    background-color: #FFB545;
//     }
}

.influencer-profile__image--curve {
  position: relative;
  background-color: #fff;
  padding: 6px;
  border-radius: 10px 0 0 10px;
  text-align: center;
  transition: border-radius 450ms ease-in-out;
  width: 22px;
  margin-left: auto;
  margin-right: 37px;
  margin-top: 3px;
  z-index: 0;
}







.influencer-profile__image--curve::before,
.influencer-profile__image--curve::after {
  content: "";
  position: absolute;
  display: block;
  pointer-events: none;
  width: 11px;
  height: 11px;
  transition: width 450ms ease-in-out, height 450ms ease-in-out, box-shadow 450ms ease-in-out, border-bottom-right-radius 450ms ease-in-out, border-top-right-radius 450ms ease-in-out;
  z-index: -2;
}

.influencer-profile__image--curve::after {
  bottom: -7px;
  right: -8px;
  border-radius: 10px;
  box-shadow: 0 -19px 0 0 #f2f2ff;
  transform: translateY(100%);
}

.influencer-profile__image--curve::before {
  top: 6px;
  right: -8px;
  border-radius: 10px;
  box-shadow: 0 6px 0 0 #f2f2f2;
  transform: translateY(-100%);
}


.influencer-profile__image--curve-left {
  position: relative;
  background-color: #fff;
  padding: 6px;
  border-radius: 10px 0 0 10px;
  text-align: center;
  transition: border-radius 450ms ease-in-out;
  width: 44px;
  margin-left: 35px;
  margin-right: auto;
  margin-top: -12px;
  z-index: 0;
  transform: rotate(180deg);
}






.influencer-profile__image--curve-left::before,
.influencer-profile__image--curve-left::after {
  content: "";
  position: absolute;
  display: block;
  pointer-events: none;
  width: 11px;
  height: 11px;
  transition: width 450ms ease-in-out, height 450ms ease-in-out, box-shadow 450ms ease-in-out, border-bottom-right-radius 450ms ease-in-out, border-top-right-radius 450ms ease-in-out;
  z-index: -2;
}

.influencer-profile__image--curve-left::after {
  bottom: -8px;
  right: -5px;
  border-radius: 10px;
  box-shadow: 0 -19px 0 0 #f2f2ff;
  transform: translateY(100%);
}

.influencer-profile__image--curve-left::before {
  top: 3px;
  right: -10px;
  border-radius: 10px;
  box-shadow: 0 6px 0 0 #f2f2f2;
  transform: translateY(-100%);
}


.influencer-user__location {
  p {
    font-size: 14px;
    word-break: break-all;
  }

  .discover_subscription_dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
  }

  .discover_subscription_dot_non_subscribed{
    background-color: #F23434;
    ;
    }
.discover_subscription_dot_active{
    background-color: #5FC996;
    ;
    }
.discover_subscription_dot_non_active{
   background-color: #FFD645;
    }
}
.influencer-user__info {
  background-color: $color-white;
  padding: 1.25rem 1rem;
  margin-left: -1.25rem;
  border-radius: 0 1.25rem 1.25rem 0;
  margin-bottom: 1.25rem;
  position: relative;

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  &::after {
    content: '';
    background: $color-f2f2f2;
    height: 2em;
    width: 2em;
    position: absolute;
    top: 100%;
    left: 0em;
    border-radius:16px 0 0 0;
    box-shadow: 0 -16px 0 0 #fff;
    @media (width < 992px) {
      content: none;
    }
  }

  &::before {
    content: '';
    background:$color-f2f2f2;
    height: 2em;
    width: 2em;
    position: absolute;
    bottom: 100%;
    left: 0em;
    border-radius: 0 0 0 16px;
    box-shadow: 0 16px 0 0 #ffffff;
    @media (width < 992px) {
      content: none;
    }
  }
}

.influencer-user__intro {
  p{
    @media (width > 991px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    word-break: break-word;
    
    }
  }
  &.more-text  {
    p{
      overflow: visible;
      -webkit-line-clamp:inherit;
      display: block;
    }
  }
}

.common-tab {
  display: inline-flex;
  padding: .375rem;
  background: #e3e3e3;
  border-radius: .4375rem;
  margin-bottom: 1.5rem;

  &-menu {
    padding: .125rem 1.25rem;
    font-size: 1rem;
    // cursor: pointer;

    &.active,
    &.Mui-selected {
      background: #fff;
      border-radius: 4px;

    }
  }

  &.MuiTabs-root {
    min-height: inherit;
  }

  .MuiTabs-flexContainer {

    .MuiTab-root {
      padding: .3125rem .625rem;
      min-height: inherit;
      max-width: inherit;
      min-width: inherit;

    }

    .MuiTab-root.Mui-selected {
      background: #fff;
      border-radius: 4px;
      color: $color-body;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}


.influencer-profile__social-chan {
  color: $color-body;
  text-decoration: none;
  padding-bottom: 0px;
  border-bottom: 1px solid $color-body;
  margin-bottom: 1.5rem;

  &:hover {
    color: $color-838383;
    border-bottom-color: $color-838383;
  }
}

.card-follower {
  display: flex;
  border: 1px solid $color-black-5d;
  padding: 1rem 1.25rem;
  border-radius: .625rem;
  flex: 1;
  align-items: center;

  .card-follower__left {
    display: flex;
    flex-direction: column;
  }


  .card-follower__icon {
    color: $color-b5b5b5;
    margin-left: auto;
    font-size: 2.3125rem;
  }
}

.card-follower__title {
  font-size: 18px;
  color: #5c5c5c;
  padding-bottom: 5px;
}

.card-follower__value {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.followers-info {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.follower-sec {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}



.follower-sec-inline {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 1.25rem;
  background: #f2f2f2;
  border-radius: 10px;
  column-gap: 4rem;
  row-gap: 1rem;

  .card-follower-inline {
    &:first-of-type {
      @media (width > 1400px) {
        margin-right: 3.125rem;
      }
    }
  }
}

.card-follower-inline {
  display: flex;
  flex-direction: column;
  min-width: 18%;

  &-list {
    display: flex;
    gap: 1rem;

    .card-follower__value {
      flex: 0 0 auto;
      &:first-of-type {
        border-right: 1px solid #000;
        margin-right: -1px;
        padding-right: 1rem;
      }
    }
  }

}


.influencer-profile__common {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.w-bdr {
    border: 1px solid $color-black-5d;
    border-radius: 0.625rem;
  }
  &.geo-specific{
    .influencer-profile__common__inner:last-of-type {
      border-bottom: none;
      border-radius: 0 0 14px 14px;
    }
  }
  &.social-share{
    .influencer-profile__common__inner {
      border-bottom: none;
      border-radius:14px;
    }
  }
  &.channel-insights{
    
    .influencer-profile__common__inner:last-of-type:not(:nth-of-type(3)) {
      border-bottom: none;
      border-radius: 0 0 14px 0;
    }
    
    .influencer-profile__common__inner:nth-last-child(2) {
      border-bottom: none;
      border-radius: 0 0 0px 14px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 0.25rem;
  }
}

.influencer-profile__common {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.25rem;
}

.influencer-profile__common__inner {
  background: $color-white;
  border-radius: $border-radius__10;
  // border: .0625rem solid rgba($color-black, 0.25);
  padding: 1.25rem 1.75rem;
  border-bottom: 1px solid $color-ededed;

  &.extra__spacing {
    padding-top: 2.25rem;
  }

  table {
    width: 100%;

    td {
      font-size: $font-size-sml;
      padding-bottom: .5rem;

      &:last-of-type {
        width: 20%;
        text-align: right;
      }
    }
  }

  &:nth-of-type(odd) {
    border-bottom: 1px solid $color-ededed;
    border-radius: 10px 0 0 0;
    border-right: 1px solid $color-ededed;
  }
  &:nth-of-type(even) {
    border-radius: 0px 10px 0px 0;
  }

  // &:nth-of-type(2) {
  //   border-bottom: 1px solid $color-ededed;
  //   border-radius: 0px 10px 0 0;
  // }

  &:nth-of-type(3) {
    border-radius: 0px 0px 0 10px;
    border-right: 1px solid $color-ededed;
  }
  // &:last-of-type {
  //   border-bottom: none;
  //   border-radius: 10px 0 0 0;
  // }
  &:last-child:has(.MuiGrid-grid-md-12) {
    border-radius: 0 0 10px 10px;
  }
  // &:has(.MuiGrid-grid-md-12) {
  //   border-radius:  0 0 10px 10px;
  // }
 }

.inner-heading {
  font-size: $font-size-large;
  font-weight: bold;
  color: $color-body;
  padding-bottom: 1rem;
  display: block;

  &.sml {
    font-size: $font-size-body;
  }

  .inner-heading-symbol {
    border: none;
    font-weight: normal;
    font-size: $font-size-body;
    padding-left: .25rem;
  }
}

.gender-value-sec {
  display: flex;
  gap: 5rem;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 1.2rem;

  &.gender {
    margin-top: 2.5rem;
  }
}

.gender-value {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 20px;
    background: #000;
    margin-right: 8px;
    right: 100%;
    top: 2px;
    border-radius: 6px;

  }

  &.male {
    &:before {
      background-color: #3592FC;
    }
  }

  &.female {
    &:before {
      background-color: #FF78B1;
    }
  }

}

.age-range {
  .highcharts-grid-line {
    display: none;

    .highcharts-xaxis {
      display: none;
    }
  }

  .chart-label {
    display: none;
  }
}

.cities {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-right: .625rem;
}

/* -----------------------------------------------------
	CSS Progress Bars
-------------------------------------------------------- */
.cssProgress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .progress1,
  .progress2,
  .progress3 {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: "Roboto", sans-serif;
  }

  .cssProgress-bar {
    display: block;
    float: left;
    width: 0%;
    height: 100%;
    background: $bar-default-bg;
    transition: width 0.8s ease-in-out;
  }

  .cssProgress-label {
    position: absolute;
    overflow: hidden;
    left: 0px;
    right: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.7em;
    text-align: center;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
  }

  .cssProgress-info {
    background-color: $bar-info-bg !important;
  }

  .cssProgress-danger {
    background-color: $bar-danger-bg !important;
  }

  .cssProgress-success {
    background-color: $bar-success-bg !important;
  }

  .cssProgress-warning {
    background-color: $bar-warning-bg !important;
  }

  .cssProgress-right {
    float: right !important;
  }

  .cssProgress-label-left {
    margin-left: 10px;
    text-align: left !important;
  }

  .cssProgress-label-right {
    margin-right: 10px;
    text-align: right !important;
  }

  .cssProgress-label2 {
    display: block;
    margin: 2px 0;
    padding: 0 8px;
    font-size: 0.8em;

    &.cssProgress-label2-right {
      text-align: right;
    }

    &.cssProgress-label2-center {
      text-align: center;
    }
  }

  .cssProgress-stripes,
  .cssProgress-active,
  .cssProgress-active-right {
    background-image: linear-gradient(-45deg,
        rgba(255, 255, 255, 0.125) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.125) 50%,
        rgba(255, 255, 255, 0.125) 75%,
        transparent 75%,
        transparent);
    background-size: 35px 35px;
  }
}






/* -----------------------------------------------------
	Progress Bar 2
-------------------------------------------------------- */





.percent-bar {
  min-width: 2.5rem;
}

.progress-bars {
  display: flex;
  align-items: center;
  column-gap: .625rem;
  position: relative;
  overflow: hidden;
  max-width: 40%;
  min-width: 40%;
}

.p-span {
  color: #4c4c4c;
  font-weight: 400;
  font-size: $font-size-body;
}

@container inflProfile (width < 11.875rem) {
  .info-prof-flw-data {
    flex-direction: column;
    text-align: center !important;

    li {
      padding: 0 !important;
      padding-bottom: 10px !important;

      &:first-of-type {
        &:not(:only-of-type) {
          text-align: center !important;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.progress2 {

  .cssProgress-bar {
    height: 1.25rem;
    border-radius: 6px;
  }

  .cssProgress-label {
    line-height: 18px;
  }
}



.engagement-info {
  display: flex;
  gap: 1rem;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid #000;

    &:last-of-type {
      border-right: none;
    }
  }
}


.engagement-info li {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid $color-ededed;
}

.engagement-info {
  display: flex;
  gap: 1rem;
  // border-bottom: 1px solid $color-ededed;
  // padding-bottom: 1.25rem;

  &:last-of-type {
    border-bottom: none;
  }

  &-left {
    flex: 0 0 calc(75% - 2rem);
    padding-right: 3rem;
    container: engagement-info-left / inline-size;
  
  }
    
  &-right {
    flex: 0 0 25%;
    padding-left:1.125rem;
    container: engagement-info-right / inline-size;
    border-left: 1px solid #ededed;
  }
    
}
//  @container engagement-info-right (width > 700px) {
//   .engagement-info-right {
//    flex: 0 0 25%;
//   }
//  }
// @container engagement-info-left (width > 700px) {
//   .engagement-info-left {
//   flex: 0 0 calc(75% - 2rem);
//   }
// }

.engagement-info-sec {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.25rem 0;
}


.engagement-info-left strong,
.engagement-info-left .engagement-info {
  padding-left: 1.25rem;
}

.engagement-info-left strong {
  display: block;
  padding-bottom: 0.5rem;
  font-size: $font-size-large;
}

.engagement-info-left li {
  span {
    &:first-of-type {
      color: $color-5c5c5c;
    }

    &:nth-of-type(2) {
      font-size: 1.375rem;
      color: $color-black;
      font-weight: bold;
    }
  }
}


.card-posts {
  margin-bottom: .625rem;

  .card-posts-info {

    ul {
      padding-left: 0;
      list-style: none;
      font-size: .8125rem;
      margin-bottom: 0;
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    li {
      display: flex;
      padding-bottom: .3125rem;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
      color: #fff;
      flex: 0 0 calc(25% - 0.5rem);
      text-align: center;
      justify-content: flex-start;

      svg {
        margin: 0 auto;
      }

      .MuiIconButton-root {
        &:hover {
          background-color: transparent;
        }
      }
    }

    strong {
      font-weight: 400;
    }
  }

  .card-posts-image img {
    object-fit: cover;
  }

  &.card-posts-stories {
    
    .card-posts-image {
      background: transparent;
      max-height: inherit;
      min-height: inherit;
      margin: 0 auto;
      text-align: center;

      img {
        width: 100px;
        height: 100px;
        max-height: inherit;
        min-height: inherit;
        object-fit: contain;
        border-radius: 50%;
        border: 5px solid $color-white;
      }
    }
    ul {
      justify-content: space-around;
    }
    li {
      flex:  0 0 auto ;
      padding-bottom: 0;
    }
  }
  
}
.posts-story-grid {
  flex: 0 0 150px;
}


.card-posts {
  border-radius: 0px;
  margin-bottom: 0;

  .profile-youtube .card-posts-info,
  .profile-youtube .card-posts-image,
  .profile-youtube .card-posts-image img {
    border-radius: 0;
  }

  .card-posts-image,
  .card-posts-image img {
    border-radius: 0;
    background: $color-white;
    max-height: 16.5rem;
    min-height: 16.5rem;
    margin-bottom: 0.25rem;
  }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    background-color: $color-white;
    border-radius: 0.625rem;
  }

  .card-posts-info {
    padding: .9375rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: rgba($color-black, 0.7);
    border-radius: 0.625rem;
    gap: .625rem;



    ul:first-of-type {
      padding-right: 0px;
      border-right: none;
      width: calc(100% - 0px);

      &:not(:only-of-type) {
        border-right: 1px solid $color-ededed;
        padding-right: .5rem;
      }
    }

  }


}

.sec-media-posts {
  background-color: $color-f2f2f2;
  border-radius: 0.625rem;
  padding: 0.625rem 0.625rem 1rem;
  margin-bottom: 0.625rem;
  width: 100%;

  &__title {
    font-size: $font-size-large;
    display: block;
    padding-bottom: .5rem;
  }
}

.sec-highchart {
  .highcharts-container {
    margin: 0 auto;
  }
}
@media (width > 61.9375rem) {
  .influencer-profile {
    margin-top: -1rem;
  }
}

@media (width <= 61.9375rem) {
  // .influencer-profile__left {
  //   display: none;
  // }
    .influencer-profile {
      flex-direction: column ;
    }
  .card-follower-inline {
    flex: 0 0 calc(50% - 2rem);
  }
  .follower-sec-inline {
    column-gap: 2rem;
  }
  .influencer-profile__left-inner-mobile {

  }
    .influencer-profile__common__inner {
      &.extra__spacing {
        padding-top: 1.25rem;
      }
    }
  
    .influencer-profile__common__box .inner-heading {
      min-height: inherit !important;
    }
  
    .influencer-user__info {
      background-color: transparent;
      padding: 0;
      margin-left: 0;
  
      h3 {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  
    .influencer-profile__left {
      flex: 0 0 auto;
    }
  
    .influencer-profile__left-inner {
      background-color: transparent;
      padding: 0;
      height: auto;
      position: static;
      top: auto;
    }
  
    .influencer-profile__social-chan {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }
  
    .influencer-profile__image.influencer-user-profile-left {
      width: 100px;
      height: 100px;
      margin: 0;
      margin-right: 0px;
      margin-right: 15px;
    }
  
    .influencer-user-profile {
      display: flex;
      flex-wrap: wrap;
  
      &-right {
        flex: 9999;
        align-items: flex-start;
        text-align: left;
        display: flex;
        flex-direction: column;
      }
    }
  
    .influencer-profile__common__inner:first-of-type {
      border-radius: $border-radius__10;
    }
    .common-tab {
    &.common-tab-mobile {
      
        background-color: transparent;
        padding: 0;
        gap: 1rem;
      
  
      .common-tab-menu {
        border: 1px solid #000;
        background-color: #fff;
        border-radius: 40px;
        font-size: 0.875rem;
        flex: 0 0 calc(50% - 1rem);
        text-align: center;
  
        &:hover,
        &.active {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
  
}
@media (width < 48rem) {
  .follower-sec {
    gap: 0.5rem;
    
  }
  .card-follower {
    padding: 0.5rem;
  }

  .card-follower__value {
    font-size: 1.125rem;
  }

  .card-follower__title {
    font-size: 0.75rem;
  }

  .card-follower .card-follower__icon {
    font-size: 1.5rem;
  }
  .engagement-info-right .highcharts-root,.engagement-info-right .highcharts-container,
  .influencer-profile__common__box .highcharts-container,
  .influencer-profile__common__box .highcharts-root {
    width: 100% !important;
    max-width: 100%;
  }
    .engagement-info-left {
      flex:0 0 100%;
      padding-right: 0;
      margin-right: 0;
      .engagement-info{
        flex-wrap: wrap;
      }

    }
    .engagement-info li { 
      flex: 0 0 47%;
    }
    .engagement-info-right {
      flex: 0 0 100%;
    }
    .follower-sec-inline {
      column-gap: 2rem;
    }
    
}

@media (width < 36rem) {
  // .follower-sec-inline {
  //     column-gap: 1rem;
  // }
    .card-follower-inline {
      flex: 0 0 calc(50% - 1rem);
    }
    .engagement-info-left li span:first-of-type {
      font-size: 0.875rem;
    }
    .engagement-info-left li span:nth-of-type(2) {
      font-size: 1.125rem;
    }
}

.influencer-user__category {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  padding-top: 10px;
    
  span {
    background: #fff;
    padding: .25rem .625rem;
    border-radius: 10px;
    font-size: $font-size-xsml;
    display: flex;
    word-break: break-word;
  }
}

// .card-follower-inline:nth-of-type(2),
// .card-follower-inline:nth-of-type(3),
// .card-follower-inline:nth-of-type(4) {
//   margin-left: auto;
//   margin-right: auto;
// }
// .card-follower-inline:nth-of-type(2) {
// margin-right: 4rem;
// }
// .card-follower-inline:nth-of-type(3) {
//   margin-right: 0;
// }reel
// .card-follower-inline:nth-of-type(4) {
//   margin-right: 0;
// }










// .infl-profile-view {
//   margin-bottom: 20px;
// }

// .influ-prof-img-sec {
//   border: .0625rem solid #000;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding: 1.25rem;
//   border-radius: .875rem;
//   container: inflProfile / inline-size;

//   @media only screen and (width < 48rem) {
//     margin-bottom: 1.25rem;
//   }

//   .influ-prof {
//     text-align: center;

//     &-img {
//       margin: 0 auto 15px;
//       border-radius: 50%;
//       position: relative;
//       max-width: 120px;
//       height: 120px;
//       border-radius: 50%;
//       border: .0625rem solid #dee2e6;


//       img {
//         vertical-align: middle;
//         border-style: none;
//         margin: 0 auto;
//         border-radius: 50%;
//         max-width: 100%;
//         height: 100%;
//       }
//     }

//   }


//   .influ-prof-verified-img {
//     position: absolute;
//     bottom: 0;
//     right: 14px;
//     width: 30px;
//     height: 30px;
//     z-index: 0;

//     img {
//       width: 15px;
//       height: 15px;
//       position: absolute;
//       left: 0;
//       top: 12px;
//       border: .0625rem solid #fff;
//       border-radius: 50%;
//       background: #fff;
//     }

//     img:last-of-type {
//       left: 7px;
//       top: 7px;
//       z-index: -1;
//     }
//   }

//   .influ-prof-info {
//     text-align: center;
//     padding-bottom: 1rem;

//     h3 {
//       font-size: 1.5rem;
//       margin-bottom: 0;
//     }

//     .influ-prof-info-social-chan {
//       color: $color-primary;
//     }
//   }


//   .influ-prof a {
//     font-size: 1rem;
//     text-align: center;
//     color: $color-primary;

//     @media (width < 62rem) {
//       word-break: break-word;
//     }
//   }

//   .influ-prof-info-loc {
//     list-style: none;
//     padding-left: 0;
//     margin-bottom: .625rem;
//   }

//   .info-prof-flw-data {
//     list-style: none;
//     display: flex;
//     justify-content: space-around;
//     padding-left: 0;
//     margin-bottom: 0;


//     li {
//       .flw-title {
//         display: flex;
//         flex-flow: column wrap;
//       }

//       .flw-count {
//         font-size: 1.25rem;
//         font-weight: 800;
//         line-height: normal;
//       }

//       &:first-of-type:not(:only-of-type) {
//         text-align: right;
//       }

//       &:not(:only-of-type) {
//         flex: 1;
//         padding-right: 1.875rem;
//         position: relative;
//         color: #4c4c4c;

//         @media (width < 62rem) {
//           padding-right: 1.25rem;
//         }
//       }

//       &:last-of-type:not(:only-of-type) {
//         padding-left: 1.875rem;
//         padding-right: 0;

//         @media (width < 62rem) {
//           padding-left: 1.25rem;
//         }
//       }
//     }
//   }
// }

// .infl-profile-detail-sec {
//   padding: 1.25rem;
//   background-color: $color-f2f2f2;
//   border-radius: $border-radius-card;
//   position: relative;
//   display: flex;

//   flex-direction: column;

//   @media (width > 47.9375rem) {
//     height: 100%;
//   }


//   .influ-subheading {
//     font-size: $font-size-sml;
//     color: $color-838383;
//     display: block;
//   }

//   .infl-profile-detail {
//     padding-bottom: .9375rem;
//   }

//   .influ-summary {
//     font-size: $font-size-sml;
//   }
// }

// .infl-profile-detail-action {
//   position: absolute;
//   right: 1.25rem;
//   top: 1rem;

//   @media (width < 48rem) {
//     position: static;
//     margin-bottom: 15px;
//   }
// }

// .influ-profile-social-media-card {
//   display: flex;
//   flex-direction: column;
//   padding: .625rem;
//   border-radius: $border-radius__10;
//   background-color: $color-white;
//   border: 1px solid rgba($color-black, 0.25);
//   font-size: $font-size-sml;
// }

// .influ-profile-social-media-left {
//   display: flex;
//   flex-direction: column;
//   padding: .625rem;
//   border-radius: .625rem;
//   background-color: #ffffff;
//   border: .0625rem solid rgba(0, 0, 0, 0.25);
//   flex: 0 0 100%;

//   @media only screen and (width > 47.9375rem) {
//     flex: 0 0 10rem;
//   }

//   .influ-profile-social-media-card {
//     padding: 0;
//     border-radius: 0;
//     border: none;
//     margin-bottom: .9375rem;
//   }

//   .social-media-card-sec-title {
//     padding-bottom: .9375rem;
//   }
// }

// .influ-profile-social-media-sec {
//   display: flex;
//   width: 100%;
//   gap: .9375rem;
//   margin-top: auto;

//   @media only screen and (width < 48rem) {
//     flex-wrap: wrap;
//   }
// }

// .influ-profile-social-media-right {
//   display: flex;
//   flex: 1;
//   flex-wrap: wrap;
//   gap: .9375rem;

//   .influ-profile-social-media-card {
//     display: flex;
//     flex: 1 0 auto;
//     flex-wrap: wrap;
//     min-width: 10.625rem;
//     min-height: 4.5rem;

//     @media only screen and (width < 75rem) {
//       min-width: 7rem;
//       min-height: 3.5rem;
//     }
//   }
// }

// .audience-type-wrapper {
//   background: $color-f2f2f2;
//   border-radius: $border-radius-card;
//   padding: .75rem 1.875rem 0 1.875rem;
//   width: 100%;
// }

// .sec-infl-profile-common {
//   margin-bottom: 1.25rem;
//   padding: 0 1rem;

//   @media only screen and (width > 35.9375rem) {
//     padding: 0 1.25rem;

//   }

//   @media only screen and (width > 47.9375rem) {
//     padding: 0 1.875rem;

//   }

//   .container {
//     background: $color-f2f2f2;
//     padding: 1.25rem;
//     border-radius: $border-radius-card;

//     @media only screen and (width > 47.9375rem) {
//       padding: 1.25rem 1.875rem 1.875rem;

//     }
//   }

//   &-title {
//     font-weight: bold;
//     padding-bottom: .625rem;
//     display: block;

//   }

//   &-inner {
//     background: $color-white;
//     border-radius: $border-radius__10;
//     border: .0625rem solid rgba($color-black, 0.25);
//     padding: .625rem 1.25rem;

//     table {
//       width: 100%;

//       td {
//         font-size: $font-size-sml;
//         padding-bottom: .5rem;

//         &:last-of-type {
//           width: 20%;
//           text-align: right;
//         }
//       }
//     }
//   }

//   &.infl-profile-view {
//     .container {
//       background-color: transparent;
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }

// .section-header-symbol {
//   font-size: $font-size-xsml;
// }

// .audience-data-sec {
//   display: flex;
//   flex-direction: column;
// }

// .audience-data {
//   padding: .625rem;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   margin-bottom: .9375rem;
//   min-height: 8.25rem;
//   border-radius: .25rem;
// }

// .audience-data-value.reach {
//   margin-bottom: 0;
//   line-height: 3.125rem;
//   margin-top: auto;
//   align-self: center;
// }

// .audience-data-value {
//   margin-top: auto;
//   margin-bottom: auto;
//   font-size: 3.125rem;
//   font-weight: 800;
// }

// .audience-data .audience-data-exact-value {
//   align-self: center;
//   margin-bottom: auto;
// }

// .info-prof-flw-data li:first-of-type:not(:only-of-type) {
//   text-align: right;
// }

// .info-prof-flw-data li:not(:only-of-type) {
//   flex: 1;
//   padding-right: 1.875rem;
//   position: relative;
//   color: #4c4c4c;
// }

// .info-prof-flw-data li .flw-title {
//   display: flex;
//   flex-flow: column wrap;
// }

// .info-prof-flw-data li .flw-count {
//   font-size: 1.25rem;
//   font-weight: 800;
//   line-height: normal;
// }

// .info-prof-flw-data li:first-of-type:not(:only-of-type):after {
//   content: "";
//   background: #c9c9c9;
//   width: .125rem;
//   height: 50%;
//   position: absolute;
//   right: 0;
//   top: 50%;
//   transform: translateY(-50%);
// }

// .info-prof-flw-data li:last-of-type:not(:only-of-type) {
//   padding-left: 1.875rem;
//   padding-right: 0;
// }



// .highchart-centered {
//   .highcharts-container {
//     margin: 0 auto;
//   }
// }

// .cities-tab-sec {
//   display: flex;
//   flex-direction: column;

//   .MuiTabs-root {
//     min-height: inherit;
//   }

//   .city-section {
//     font-family: $font-family-body;
//   }

//   // .city_tab_1 {
//   //   border: 1px solid #dee2e6;
//   //   // border-radius: 1.25rem 0 0 1.25rem;
//   //   min-width: 3.125rem;
//   //   padding: 0 .75rem;
//   // }

//   // .city_tab_2 {
//   //   border: 1px solid #dee2e6;
//   //   // border-radius: 0 1.25rem 1.25rem 0;
//   //   min-width: 3rem;
//   //   padding: 0;
//   // }

//   .MuiBox-root {
//     border-bottom: none;
//     background-color: none;
//   }

//   .MuiTabs-indicator {
//     height: 0px;
//   }

//   .MuiTab-root {
//     min-height: inherit;
//     font-size: $font-size-xsml;
//     font-weight: 400;
//     padding: .3125rem .9375rem;
//     align-self: flex-start;
//     min-width: inherit;
//   }

//   // .MuiTabs-flexContainer {
//   //   .MuiTab-root.Mui-selected {
//   //     background: $color-black;
//   //     color: $color-white;
//   //   }
//   // }
// }





// .sec-highchart {
//   .highcharts-container {
//     margin: 0 auto;
//   }
// }


// // GENDER SPLIT SECTION
// .ratio-sec {
//   display: flex;
//   flex-wrap: wrap;
//   margin-bottom: 1rem;

//   .mf-ratio {
//     margin-right: auto;
//     flex: 0 0 50%;
//     border-right: 1px solid $border-color-card;
//   }

//   .ad-ratio {
//     display: flex;
//     flex-direction: column;
//     padding-left: .625rem;
//     text-align: right;
//   }


// }


// .modal-send-campaign {
//   .common-table-body {
//     tr {
//       &:first-of-type {
//         td {
//           border-top: 1px solid rgba(0, 0, 0, 0.3);

//           &:last-of-type {

//             border-radius: 0 14px 0 0;
//           }

//           &:first-of-type {
//             border-top: 1px solid rgba(0, 0, 0, 0.3);
//             border-radius: 14px 0 0 0;
//           }
//         }
//       }
//     }
//   }
// }

/* Custom Slick Slider Styles */
.custom-slick-slider .slick-slide {
  padding: 0 5px;  /* Adds horizontal space between posts */
}

/* Optional: Increase the space between the slides in larger screens */
@media (min-width: 1024px) {
  .custom-slick-slider .slick-slide {
    padding: 0 10px;
  }
}

/* Custom Arrows Styling */
.custom-slick-slider .slick-prev,
.custom-slick-slider .slick-next {
  color: grey !important;
  top: 50% !important;
  z-index: 1;
  transform: translateY(-50%);  /* Vertically center the arrows */
}

.custom-slick-slider .slick-prev:before,
.custom-slick-slider .slick-next:before {
  font-family: 'slick' !important;
  font-size: 20px !important; 
  line-height: 1 !important;
  opacity: .75 !important;
  color: grey !important;
  -webkit-font-smoothing: antialiased !important;
}

/* Move the arrows closer to the posts */
.custom-slick-slider .slick-prev {
  left: -18px !important;
}

.custom-slick-slider .slick-next {
  right: -18px !important;
}

