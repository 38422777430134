.my-campaign-card-container {
  @media (width < 768px) {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  }
}

.my-campaign-card {
  display: flex;
  border-bottom: 1px solid rgba($color-black, 0.3);
  margin-bottom: 0;
  padding: .625rem;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  @media (width < 768px) {
      border: 1px solid #d7d7d7;
      padding: 1.25rem;
      border-radius: $border-radius-card;
      box-shadow: 0 2px 5px rgba($color-black, 0.3);
  }

  &:hover {
    background-color: #fafafa;
  }

  @include breakpoint(medium) {
    padding: 1.5rem 2.625rem 1.75rem;
  }

  &-left {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    @include breakpoint(small) {
      align-items: center;
    }
    @include breakpoint(medium) {
      margin-bottom: 0;
      margin-right: .625rem;
    }

    > img {
      width: 3rem;
      border-radius: .4375rem;
      flex: 0 0 3rem;
      margin-right: 0.625rem;
      max-height: 3rem;
      min-height: 3rem;
      object-fit: contain;
      border: 1px solid rgba($color-black, 0.3);

    }

    h5 {
      cursor: pointer;
      color: $color-black;
      font-size: 1rem !important;
      font-weight: 400;
      @include breakpoint(small) {
        margin-bottom: 0;
      }
    }
  }

  &-right {
    display: flex;
    align-items: inherit;
    flex-direction: column;
    padding: 1.25rem 0 0;

    @include breakpoint(small) {
      align-items: center;
      flex-direction: row;

    }

    .status-heading {
      cursor: pointer;
      min-height: 150px;
      margin-right: .625rem;
      padding: .9375rem .625rem;
      position: relative;
      list-style: none;
      flex: 0 0 calc(20% - 3.2rem);
      text-align: center;
      min-height: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid $color-bcc2ce;
      background-color: $color-white;
      color: rgba($color-black, 0.3);
      font-size: $font-size-sml;
      margin-right: 0;

      @include breakpoint(medium) {
        height: 100%;
        padding: .3125rem .625rem;
      }

      @include breakpoint(small) {
        margin-bottom: 0;
      }

      @media (max-width: 990px) {
        min-height: 70px;
        padding: 5px;
      }
      @media (width <  768px) {
        min-height: inherit;
        padding: 10px 5px;
        display: none;
        &.selected.previous {
          display: none;
        }
        &.selected {
          display: flex;
        }
      }
      &.mark-complete-event {
        color: #000
      }

      strong {
        display: block;
        @media (max-width: 767px) {
          display: flex;
        }
      }

      &.selected {
        background-color: $color-f5f8fc;
        color: $color-black;
      }

      &.proposal-accepted {
        background: #caffe6;
      }

      &.proposal-negotiating {
        background: #f6e9ff;
      }

      &.proposal-rejected {
        background: #f2cdcd;
      }

      &.proposal-pending {
        background: #ffeed9;
      }

      .btn {
        padding: 2px 10px !important;
        border-radius: 30px;
        font-size: $font-size-xsml;
        line-height: 0.75rem;
      }



    }

    .arrow {
      display: flex;
      justify-content: center;
      margin: 0 1.25rem;

      .material-symbols-rounded {
        transform: rotate(0deg);
      }

      @media (max-width: 767px) {
        display: none;
        .material-symbols-rounded {
          transform: rotate(90deg);
        }
      }
    }
    &.active {
      .status-heading {
        @media (max-width: 767px) {
          display: flex;
          
            &.selected.previous {
              display: flex;
            }
          
            &.selected {
              display: flex;
            }
        }
      }
      .arrow {
        display: flex;
      }
    }
  }
}
.send-invoice-sec {
  @media (max-width: 767px) {
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: 6px;
    margin-top: 10px;
  }
}
.campaign-creative {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  @media (width < 768px){
  gap: 5px;
  align-items: center;
  font-size: 12px;
  margin-bottom: 0;
  
  }
  li {
    display: flex;
    align-items: center;
    gap: 0px;
    padding-right: 8px;
  }

  .status {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    color: black;
    border: 0.25px solid black;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    &.color-approve {
      background-color: #d6fad5;
    }

    &.color-reject {
      background-color: #f2cdcd;
    }

    &.color-pending {
      background-color: #ffeed9;
    }
  }

}

.status-heading-inner {
  display: flex;
  @media (width > 767px) {
    flex-direction: column;
  }
}

.status-heading[class*="proposal-"] {
  color: #000;
}

.campaign-det {
  .campaign-detail-title-top {
    padding: 0px 1.25rem;

    @include breakpoint(small) {
      padding: 0px 0 0px 2.625rem;
    }
  }
}

.main-campaign-det {
  .campaign-detail-title-top {
    padding-left: 0;
    margin-bottom: 1.25rem;
  }
}

.campaign-detail-title-top {
  .title-top-title {
    flex: 1;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}