.deliverable-tool {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  overflow: hidden;
  background-color: $color-f2f2f2;
  flex-direction: column-reverse;

  label {
    flex: 1;
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;

    &.infl-range-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked+label {
    transition: all .35s ease-in-out;
    background-color: $color-d7d7d7;
    color: $color-black;
  }

}

.deliverable-infl-range {
  display: block;
  color: $color-black-787878;
  font-size: 13px;
}

/* Profile Section */
.profile-detail {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;

  .infl-profile-count {
    // font-weight: bold;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 1.2;
  }

  .infl-profile-username {
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;
    font-size: $font-size-sml;
    color: $color-6a6a6a;
    &:hover {
      color: rgba($color-black, 0.7);
    }
  }

  .infl-profile-name {
    color: $color-black;
   
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;

  }

  .infl-profile-img-sec {
    flex: 0 0 200px;
    margin-right: 1.875rem;
    display: flex;
    padding-left: 0;
    align-self: center;
    max-width: 200px;
  }

  .infl-profile-total-cost {
    background: #f2f2f2;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 10px;
    min-width: 160px;
    justify-content: center;
    font-size: $font-size-sml;
    font-weight: bold;
    // margin-left: auto;
  }

  .creative-status-indicator {
    margin-left: auto;
  }


  .infl-profile-img {
    width: 2.875rem;
    height: 2.875rem;
    border-radius: 50%;
    border: 1px solid $color-838383;
    display: inline-flex;
    margin-right: 10px;

    img {
      border-radius: 50%;
      border: none;
      width: 2.75rem;
      height: 2.75rem;
    }
  }
}

.infl-profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  gap: .5rem;
  font-size: $font-size-sml;
  z-index: 2;
  flex: 9999;
  padding-left: 0;
  margin-right: 1.5rem;

  > li {
    list-style: none;
    background-color: $color-f2f2f2;
    border-radius: 6.25rem;
    padding: .5rem 0.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 6.5rem;
    flex: 1;

    &:after {
      content: "";
      position: absolute;

    }
    &.not-badge {
      background: transparent;
      padding-left: 0;
      .proposal-date {
          padding-left: 0;
        }
    }
  }
  &.proposal-accepted-rejected {
    flex: 0 0 45%;
      padding-left: 0;
      margin-right: auto;
      flex-wrap: wrap;
      li {
        padding: 4px 1.25rem;
        flex: 0 0 auto;
        min-width: inherit;
      }
  }
}

.proposal-header {
  display: flex;
  align-items: center;
  width: 100%;

  &-right {
    margin-left: auto;
    gap: 1.25rem;
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    justify-content: space-around;
  }

  &-left {
    flex: 1;
    // margin-right: 2.25rem;
  }

  .proposal-date {
    padding-left: 1rem;
  }
}

.proposal-main-container {
  padding-top: 2.5rem;
}

.proposal-detail-sec {
  padding-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  >.stepper-sec {
    display: none;
  }
  @include breakpoint(small) {
   >.stepper-sec {
    display: flex;
  }
}
  .proposal-inner-container {
    flex: 9999;
    position: relative;
    @include breakpoint(small) {
      max-width: calc(100% - 166px);
    }
    &.type-barter {
      max-width: 100%;
    }
  }
}

.proposal-comment-sec {
  display: flex;
  background-color: $color-page-background;
  border: 1px solid $color-bcc2ce;
  border-radius: $border-radius__10;
  padding: .625rem 1.25rem;
  margin-bottom: 1rem;
  flex-direction: column;

  @include breakpoint(small) {
    flex-direction: row;
  }


  strong {
    padding-right: 2.5rem;


    br {
      display: none;

      @include breakpoint(small) {
        display: block;
      }
    }
  }

  p {
    margin-bottom: 0;
    font-size: $font-size-sml;
  }
}


// STEPPER
.proposal-container {
  display: flex;
  flex-wrap: wrap;

}

.proposal-stepper {
  display: flex;
  flex: 0 0 7.5rem;
  margin-right: 1.25rem;
  margin-top: 3.125rem;
}

.proposal-negotiation-detail-sec {
  flex: 99999;
}


.stepper-sec {
  padding-left: 0;
  list-style: none;
  flex: 0 0 150px;
  margin-top: 3.125rem;

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    text-align: center;
    padding: .625rem 1rem;
    border: 1px solid $color-bcc2ce;
    border-radius: $border-radius__10;
    font-weight: 700;
    font-size: $font-size-sml;
    margin-bottom: 3.1rem;
    position: relative;
    min-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.inactive {
      opacity: 0.5;
    }

    &:after {
      content: "expand_more";
      font-family: 'Material Symbols Rounded';
      font-weight: normal;
      font-style: normal;
      position: absolute;
      -moz-font-feature-settings: 'liga';
      top: calc(100% + 8px);
      font-size: 24px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    // STEPPER ACTIVE
    &.stepper-active {
      background-color: $color-f5f8fc;
      color: $color-primary;
      border-color: $color-e2eefe;
      &.inactiveclass {
        border-color: $color-bcc2ce;
        color: $color-black;
        background-color: $color-white;
        opacity: 0.5;
      }
    }
    &.inactive.deal-closed {
        background-color: $color-f5f8fc;
        color: $color-black;
        border-color: $color-e2eefe;
        opacity: 1;
    }
  }
}
.remaining-step-container {
  background: #f9fafc;
  border: 1px solid #909AAC;
  padding: 6px;
  margin-bottom: 50px;
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;

  &:after{
    content: "expand_more";
    font-family: "Material Symbols Rounded";
    font-weight: normal;
    font-style: normal;
    position: absolute;
    -moz-font-feature-settings: "liga";
    top: calc(100% + 8px);
    font-size: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  &:nth-last-of-type(1){
    background-color: #f00;
    margin-bottom: 0;
    &:after{
    content: "";
  }
  
}
}
.proposal-mobile-container .remaining-step-container:nth-last-of-type(2):after {
  display: none;
  
}

.remaining-step-name {
  max-width: 150px;
  min-width: 150px;
  height: 3rem;
  background: #fff;
  text-align: center;
  padding: 0.625rem 1rem;
  border: 1px solid #bcc2ce;
  border-radius: 10px;
  font-weight: 700;
  font-size: 0.875rem;
  margin-bottom: 0;
  position: relative;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .3;
}
.deal-closed {
  .remaining-step-name {
    opacity: 1;
  }
}
.proposal-notification {
  .MuiIconButton-root {
    padding: 0;
    
  }
    .MuiBadge-colorPrimary {
      background-color: $color-primary;
    }
}

// DOWN ARROW EFFECT
.stepper-down-arrow-sec {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepper-down-arrow-sec .stepper-down-arrow {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 2px solid $color-5c5c5c;
  border-bottom: 2px solid $color-5c5c5c;
  margin: 0 0 -4px 5px;
}


.stepper-down-arrow-sec .arrow-1,
.stepper-down-arrow-sec .arrow-2,
.stepper-down-arrow-sec .arrow-3 {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
}

.stepper-down-arrow-sec .arrow-1 {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
}

.stepper-down-arrow-sec .arrow-2 {
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}

.stepper-down-arrow-sec .arrow-3 {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.tracking-details-img-preview {
  // width: 200px;
  height: auto;
  margin-bottom: 20px;
  img {
      max-width: 100%;
      object-fit: cover;
      max-width: 200px;
    }
}


.accordion-container.table-negotiation-mobile {
  background: $color-f9fafc;  
  border-color: $color-909AAC;
}


.accordion-container.acordian-blur .infl-profile-name, .accordion-container.acordian-blur .infl-profile-username, .accordion-container.acordian-blur .profile-info-img, .accordion-container.acordian-blur .infl-profile-count, .accordion-container.acordian-blur .infl-profile-text {
  filter: blur(3px);
  pointer-events: none;
}
.accordion-container.acordian-blur .infl-profile-total-price .infl-profile-count,
.accordion-container.acordian-blur .infl-profile-total-price .infl-profile-text{
  filter: blur(0);
}

.blur-section{
  filter: blur(3px);
}

.remove-link-style{
  color: inherit;
  text-decoration: inherit;
}

.proposal-mobile-container-barter .MuiAccordion-root .MuiCollapse-hidden {
  min-height: inherit !important;
  height: auto !important;
  visibility: visible;
}
.proposal-mobile-container-barter .MuiAccordion-root:after{
  display: none;
}
.proposal-mobile-container-barter .MuiAccordion-root .accordion-mobile-header:after{
  display: none;
}

.proposal-mobile-container-barter .proposal-negotiation-sec-mobile .table-proposal-body-deliverable-name span:last-of-type {
  border-radius: .875rem;
}

.proposal-mobile-container-barter .proposal-negotiation-sec-mobile .table-proposal-body-deliverable-name span:last-of-type,
.proposal-mobile-container-barter .proposal-negotiation-sec-mobile .table-proposal-body-other-column-container .table-proposal-body-rest-col-row .table-proposal-body-rest-col {
  border-bottom: none;
}