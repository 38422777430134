$color-primary: #00aae5 !default;

.brand_proposal {
    .proposals_tool {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        ul {
            display: flex;
            list-style: none;
            margin: 0px;
            margin-bottom: 0px;
            padding: 0px;
            overflow-x: auto;
            gap: .5rem;
            // justify-content: center;
            width: 100%;



            li {
                display: flex;

                font-size: $font-size-sml;
                cursor: pointer;
                font-size: $font-size-body;
                padding: .625rem 1rem;
                border: 1px solid $border-campaign-detail-card;
                margin: 0;
                border-radius: 3rem;
                justify-content: center;
                flex: 1;
                align-items: center;

                .proposals_tool_text {
                    font-size: $font-size-sml;
                    position: relative;
                }

                .proposals_tool_icon {
                    margin-right: .75rem;
                    display: inline-flex;
                    vertical-align: top;

                    svg {
                        path {
                            fill: $color-black;
                        }
                    }
                }

                &.active {
                    background-color: $color-black;
                    border-color: $color-black;
                    color: $color-white;

                    .proposals_tool_icon svg {
                        path {
                            fill: $color-white;
                        }
                    }
                }

                // margin: 0 5px;


                // @include breakpoint(small) {
                //     margin: 0 5px;
                // }

                // @include breakpoint(medium) {
                //     font-size: $font-size-body;
                //     margin: 0 10px;
                // }

                // @include breakpoint(large) {
                //     margin: 0px 30px 0px 10px;
                //     font-size: $font-size-body;

                // }

            }
        }

        >.btn {
            margin-left: auto;
        }

        .MuiFormControl-root {
            min-width: 160px;
        }
    }

    .inf-detail-selector {
        margin: 10px 0px 10px 0px;
    }
}

.input-style {
    &.input-select-small {
        .MuiSelect-select {
            padding-top: 4px;

            padding-bottom: 4px;

        }
    }
}

.proposal-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &-left {
        flex: 0 0 10.625rem;
    }

    &-right {
        flex: 9999;
        width: calc(100% - 11.625rem);
    }
}

.proposal-negotiation-sec {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;

    .inner-content {
        &:last-of-type {
            .proposal-negotiation-step {
                &:after {
                    display: none;
                }
            }
        }
    }

    .proposal-negotiation-step {
        flex: 0 0 7.25rem;
        min-height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .proposal-negotiation-detail {
        flex: 9999;
        display: flex;
        // flex-wrap: wrap;
        gap: 1rem;
        flex-direction: column;

        &.main-head {
            // margin-bottom: -2.875rem;

            .table-proposal {
                border-radius: .625rem .625rem 0 0;
                border-bottom: none;
                .table-negotiation-mobile & {
                    border-radius: 0;
                    border-color: $color-909AAC;
                    .table-proposal-header-row {
                        border-radius: 0;
                        height: 33px;
                    }
                }
            }
        }

        &.inner-content {
            .table-proposal {
                border-radius: 0;
                border: none;

            }
        }

        &:first-of-type {
            .proposal-negotiation-step {
                margin-top: 0;
                padding: 0;
                min-height: inherit;

                &:after {
                    display: none;
                }
            }
        }
    }
    &.proposal-negotiation-sec-mobile {
        gap: 0;
    }

    .proposal-status-msg{
        position: absolute;
        top:525px;
    }

    .proposal-status-msg-rejected{
        position: absolute;
        top:390px;
    }
}

.proposal-negotiation-table {
    flex: 99999;
}

.proposal-negotiation-step {
    flex: 0 0 100px;
    text-align: center;
    padding: 0.625rem;
    border: 1px solid #bcc2ce;
    border-radius: 10px;
    font-weight: 700;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    position: relative;
    align-self: flex-start;

    &:after {
        content: "expand_more";
        font-family: 'Material Symbols Rounded';
        font-weight: normal;
        font-style: normal;
        position: absolute;
        -moz-font-feature-settings: 'liga';
        top: calc(100% + 8px);
        font-size: 24px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    // &:last-of-type {
    //     &:after {
    //         display: none;
    //     }
    // }

    // STEPPER ACTIVE
    &.stepper-active {
        background-color: $color-f5f8fc;
        color: $color-3676cc;
        border-color: $color-e2eefe;
    }
}

// INFLUENCER SIDE
.inf-proposal-main {
    .proposal-negotiation-detail {
        background-color: $color-f9fafc;
        gap: 0.5rem;

        &:first-of-type {
            border-top-left-radius: .625rem;
            border-bottom-left-radius: 0.625rem;
            @include breakpoint(small) {
                margin-bottom: -22px;
            }
        }

        &:last-of-type {
            border-bottom-left-radius: 0.625rem;
        }

        &.negotiate-response-table {
            background-color: transparent;
        }
    }


    .proposal-detail-sec {
        padding-top: 0;
    }

    .proposal-negotiation-step {
        flex: 0 0 12.5rem;
        margin-left: 0.5rem;
        background-color: $color-white;
        margin-bottom: 3rem;
        padding-top: 1.1625rem;
        padding-bottom: 1.1625rem;
    }

    .proposal-negotiation-sec {

        .proposal-negotiation-detail {
            &:last-of-type {
                .proposal-negotiation-step {
                    margin-bottom: 1.5rem;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .proposal-action-btn {
        gap: 0.5rem;
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
    }
}

.proposal-detail-sec-mobile {
    .table-proposal {
        border-radius: 0 0 14px 14px;
        border: none;
    }

    .table-proposal-header {
        th {
            font-weight: normal;
        }
    }

    .table-proposal-body {
        td {
            height: 48px;

            &:last-of-type {
                border-right: none;
            }
        }
    }


    .table-proposal-total-cost-row {
        td {
            height: inherit;
        }
    }

    .accordion-container {
        border-color: $color-909AAC;
        background-color: $color-f9fafc;
        position: relative;

        &:after {
            content: "expand_more";
            font-family: 'Material Symbols Rounded';
            font-weight: normal;
            font-style: normal;
            position: absolute;
            -moz-font-feature-settings: 'liga';
            top: calc(100% + 0.5rem);
            font-size: 24px;
            left: 0;
            right: 0 !important;
            margin: 0 auto;
            text-align: center;
        }

        &.MuiAccordion-root {
            padding: 0;
            margin-bottom: 3rem;

            &:after {
                right: .5rem;
            }
        }

        &:last-of-type {
            margin-bottom: 1rem;

            &:after {
                display: none;
            }
        }

        &.table-negotation-mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .MuiAccordionSummary-root::after {
        right: 0.5rem;
    }

    .MuiAccordionSummary-content {
        display: flex;
        flex-direction: column;
        padding-left: 0.5rem;
        padding-right: 1.5rem;
        padding-top: 0.5rem;
    }

    .proposal-negotiation-step {
        margin-bottom: 0;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        margin-left: 0;
        flex: 0 0 9.375rem;

        &:after {
            display: none;
        }
    }

    .proposal-negotiation-amount {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        font-weight: bold;

    }

    .proposal-negotiation-date {
        font-size: 13px;
        color: rgb(144, 160, 182);
        font-style: italic;
        font-weight: 700;
        padding-bottom: 0.5rem;
    }

}

.table-column-accepted {
    background-color: $color-caffe6;
}

.table-column-rejected {
    background-color: $color-ffcaca;
}

.negotiation-accepted.input-section,
.negotiation-rejected.input-section {
    background: #F9FAFC;
    border-radius: 10px;
    border: 1px solid #bcc2ce;

    input {
        background-color: transparent;
        border: none;
    }

}


.product_ship_image {
    max-width: 100%;
}


.accordion-container {
    &.table-negotiation-mobile {
        &.MuiAccordion-root {
            padding: 0;
            margin-bottom: 50px;
            position: relative;
        }
        .stepper-sec {
            margin-top: 0;
            ul {
                margin-bottom: 0;
            }
            li {
                max-width: 150px;
                min-width: 150px;
                height: 3rem;
                min-height: inherit;
                display: none;
                margin-bottom: 0;
                &:after{
                    display: none;
                }
            }
        }
        &:first-of-type {
            .stepper-sec {
                li {
                &:first-of-type {
                        display: flex;
                    }
                }
            }
        }
        &:nth-of-type(2) {
            .stepper-sec {
                li {
                    &:nth-of-type(2) {
                        display: flex;
                    }
                }
            }
        }
        &:nth-of-type(3) {
            .stepper-sec {
                li {
                &:nth-of-type(3) {
                        display: flex;
                    }
                }
            }
        }
        &:nth-of-type(4) {
            .stepper-sec {
                li {
                    &:nth-of-type(4) {
                        display: flex;
                    }
                }
            }
        }
        &:nth-of-type(5) {
            .stepper-sec {
                li {
                    &:nth-of-type(5) {
                        display: flex;
                    }
                }
            }
        }
        .MuiAccordionSummary-root::after {
            top: 16px;
            right: 20px;
        }
        .MuiAccordionSummary-content {
            margin-right: 30px; 
            align-items: center;
            .stepper-sec {
                margin-right: auto;
            }   
        }
        &:after{
            content: "expand_more";
            font-family: 'Material Symbols Rounded';
            font-weight: normal;
            font-style: normal;
            position: absolute;
            -moz-font-feature-settings: 'liga';
            top: calc(100% + 8px);
            font-size: 24px;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
                
        }
    }
    .accordion-mobile-header {
        padding: 6px 20px 10px 6px;
    }
}


.proposal-header{
    .unread-notification-icon {
        position: absolute;
        width: 10px;
        height: 10px;
        left: -20px;
        border-radius: 50%;
        background-color: #00aae5;
    }
}

.proposals_tool{
    .unread-notification-icon {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        border-radius: 50%;
        background-color: #00aae5;
    }
}
// .table-negotiation-mobile {
//     .stepper-sec {
        
//         li:first-of-type {
//             display: flex;
//         }li:nth-of-type(2) {
//             display: flex;
//         }li:nth-of-type(3) {
//             display: flex;
//         }li:nth-of-type(4) {
//             display: flex;
//         }li:nth-of-type(5) {
//             display: flex;
//         }
//     }
// }