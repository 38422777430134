.brands-data-container {
    .brands-data-main {
        .run-camp-card {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }

        .brand-chart {
            font-size: 20px;
            font-weight: 400;
        }

        .brand-card {
            font-size: 20px;
            font-weight: 400;
            color: #4c4c4c;
        }

        .brand-name {
            font-weight: 600;
            font-size: 30px;
            background: -webkit-linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
}