.snapshot-card.est-pay-sec {
  box-shadow: none;
  border-radius: 5px;
}

.est-pay-row-left {
  display: flex;
  flex-wrap: wrap;
}

.est-pay-row-left .snapshot-card-row {
  flex: 0 0 33.333%;
}

.est-pay-sec {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #fff;
}

.est-pay-row .est-pay-row-left {
  padding-left: 25px;
}

.est-pay-row-left .snapshot-card-row .est-pay-title {
  color: #5d5d5d;
}

.est-pay-row {
  padding-top: 25px;
}

.est-pay-value {
  font-size: 20px;
  font-weight: 600;
}

#campaign-payment.btn {

  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

}

.est-pay-row-right .btn-dispute {
  text-decoration: underline;
  color: #00aae5;
  font-size: 13px;
}

.infl-payout-detail .est-pay-sec {
  width: 100%;
}

.est-pay-sec .account-det {
  max-width: 150px;
}



// Responsive changes

@media only screen and (max-width:767px) {
  #estimated-payment.est-pay-sec .est-pay-row-left {
    display: flex;
    flex-direction: column;
  }

  .est-pay-row {
    padding-top: 15px;
  }

  .est-pay-row .est-pay-row-left {
    padding-left: 15px;
  }

  .snapshot-row {
    flex-direction: column;
  }
}