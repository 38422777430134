.btn {
    padding: 0.63rem 1.25rem;
    text-decoration: none;
    display: inline-block;
    border: 1px solid transparent;
    font-family: $font-family-body !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-weight: normal !important;
    font-size: $font-size-sml;
    border-radius: 6px;
    transition: transform $button-cubic-bezier $button-transition-timing, border-color $button-cubic-bezier $button-transition-timing, background-color $button-cubic-bezier $button-transition-timing !important;
    box-shadow: none !important;

    &:active {
        transform: scale(.925);
        box-shadow: none;
    }

    @include breakpoint(small) {
        padding: 0.63rem 2.5rem;
    }

    &.btn-mui {
        padding: 0.38rem 1rem;
        font-size: $font-size-sml;
        font-weight: $font-weight-medium;
        line-height: 1.75;
    }

    &.btn-outline-black {
        color: #242424;
        background-color: $color-white;
        border: 1px solid #242424;

        &:hover {
            background-color: #252525;
            color: $color-white;
        }
    }

    &.btn-outline-error {
        color: $color-error;
        background-color: $color-white;
        border: 1px solid $color-error;

        &:hover {
            background-color: rgba($color-error, 0.1) !important;
            border: 1px solid $color-error;

        }
        &[disabled],
        &.disabled {
            opacity: 0.4;
        }
    }

    &.btn-black {
        color: #fff;
        background-color: #242424;
        border: 1px solid #242424;

        &:hover {
            background-color: #fff;
            color: #242424;
        }
    }

    &.btn-outline-primary {
        color: $color-black;
        background-color: $color-white;
        border: 1px solid $color-black-7;
        box-shadow: none;

        &:hover {
            border: 1px solid $color-black;
            background-color: transparent;
            color: $color-black;
            box-shadow: none;

        }
        &[disabled],
        &.disabled { 
            opacity: 0.4;
        }
        
    }

    &.btn-primary {
        border: 1px solid $color-black;
        background-color: $color-black;
        color: $color-white;
        box-shadow: none;
        &[disabled], &.disabled{
            background-color: rgba(0, 0, 0, 0.1);
            color: rgba(0, 0, 0, 0.15);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.35);
            cursor: default;
            border: 1px solid #ccc;
        }

        &:hover {
            border: 1px solid $color-black;
            background-color: $color-white;
            color: $color-black;
            box-shadow: none;

        }
    }

    &.btn-rounded {
        border-radius: 80px !important;
    }

    &.btn-sm {
        padding: 0.19rem 1rem !important;
    }

    &.btn-link {
        color: $color-primary;

    }

    &.btn-lg {
        padding: 0.625rem 1.875rem;
        line-height: 1;
    }

    &.btn-text-link {
        text-decoration: none;
        color: $color-black;
        justify-self: start;
        text-align: left;
        padding: 0;
        padding-bottom: 1px;
        border-bottom: 1px solid $color-black;
        border-radius: 0;

        &:hover {
            color: $color-838383;
            border-bottom-color: $color-838383;
        }

        &.red {
            color: $color-red;
        }

    }

    &.Mui-disabled {
        border-color: rgba(0, 0, 0, 0.12);
    }
    &.btn-request-demo {
        color: #fff;
            background-color: transparent;
            border: 1px solid #fff;
            &:hover {
                background-color: #fff;
                    color: #252525;
            }
    }
    &.btn-purple {
        background-color: $color-purple;
        color: #fff;
        border:1px solid $color-purple;
        &:hover {
            background-color: $color-white;
            color: $color-purple;
        }
    }

}